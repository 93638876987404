import { Link, useLoaderData } from '@remix-run/react';
import { type ClientLoaderFunctionArgs, redirect } from '@remix-run/react';
import { Suspense } from 'react';
import { Provider as ReduxProvider } from 'react-redux';

import { useUserAnalytics } from '../analytics/user';
import logo3d from '../assets/img/lp-3d-logo.png';
import { RequireToken } from '../components/Access';
import { LoadingSpinner } from '../components/LoadingSpinner';
import { ProvidersList } from '../components/ProvidersList';
import { UserContextProvider } from '../components/UserContext';
import {
  useVenueOwner,
  VenueContextProvider,
  VenueInitProvider,
} from '../components/Venue';
import { store } from '../store/configureStore';
import { getStaticAssetPath } from '../utils/assets';
import { setAPIServiceClientSecureToken } from '../utils/setAPIClientToken';

setAPIServiceClientSecureToken();

export const clientLoader = async (action: ClientLoaderFunctionArgs) => {
  const { venueId } = action.params;
  if (!venueId) throw redirect('/');
  return { venueId };
};

export function Component() {
  const { venueId } = useLoaderData<typeof clientLoader>();

  const providers = [
    <RequireToken />,
    <ReduxProvider store={store} children={[]} />,
    <UserContextProvider useUserAnalytics={useUserAnalytics} />,
    <VenueInitProvider />,
    <VenueContextProvider paramVenueId={venueId} />,
  ];

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <ProvidersList providers={providers}>
        <div className='w-full h-full relative flex items-center justify-center'>
          <video
            className='w-full h-full absolute top-0 left-0 object-cover z-0'
            src={getStaticAssetPath(
              'videos/onboarding-background-2023_fhd.mp4'
            )}
            muted
            loop
            autoPlay
          />
          <div className='relative w-full h-full'>
            <AtCapacityMessage />
          </div>
        </div>
      </ProvidersList>
    </Suspense>
  );
}

const atCapacityCalloutImage = getStaticAssetPath(
  'images/at-capacity-callout.png'
);

function AtCapacityMessage() {
  const venueOwner = useVenueOwner();
  return (
    <div className='w-full h-full flex flex-col items-center justify-center'>
      <div className='flex-none'>
        <img src={logo3d} className='w-50 h-20' alt='logo' />
      </div>
      <div className='flex-none text-2xl font-medium text-white'>
        <span className='text-tertiary'>{venueOwner.username}</span> wants to
        meet on Luna Park!
      </div>
      <div
        className={`
          w-3/4 lp-sm:w-1/2 py-15 px-10 mt-8
          bg-black border border-secondary rounded-2.25xl
          flex flex-col items-center gap-3.5
        `}
      >
        <div
          className={`
            flex flex-col-reverse md:flex-row items-center gap-5
            text-center md:text-left
        `}
        >
          <div className='text-white font-bold text-2xl'>
            Unfortunately, the experience you are attempting to join is full!
            Reach out to{' '}
            <span className='text-tertiary'>{venueOwner.username}</span> to let
            them know you want to join.
          </div>
          <img
            src={atCapacityCalloutImage}
            alt=''
            className='object-contain w-65'
          />
        </div>
        <div className='text-white text-xs font-light'>
          In the meantime you can check out our experiences
        </div>
        <Link to='/explore'>
          <button
            type='button'
            className='px-6 py-2 btn btn-primary text-white font-bold text-base lp-sm:text-lg'
          >
            View Luna Park Library
          </button>
        </Link>
      </div>
    </div>
  );
}
